import Text from '@/components/common/utils/Text'
import { useMainContext } from '@/core/contexts/main'
import { useState } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import styled, { css } from 'styled-components'

const ITEMS = [
    {
        header: 'faq__tab--1__header',
        body: 'faq__tab--1__body',
        tag: 'a',
    },
    {
        header: 'faq__tab--2__header',
        body: 'faq__tab--2__body',
        tag: 'b',
    },
    {
        header: 'faq__tab--3__header',
        body: 'faq__tab--3__body',
        tag: 'c',
    },
    {
        header: 'faq__tab--4__header',
        body: 'faq__tab--4__body',
        tag: 'd',
    },
]

export default function FaqAccordion() {
    const { theme } = useMainContext()

    const [expandedItem, setExpandedItem] = useState('a')

    const handleItemClick = (uuid) => {
        // console.log(uuid, expandedItem)
        if (expandedItem === uuid) {
            return
        } else {
            setExpandedItem(uuid)
        }
    }

    const renderedItems = ITEMS.map((item, index) => {
        return (
            <AccordionItem key={index} uuid={item.tag}>
                <AccordionItemHeading onClick={() => handleItemClick(item.tag)}>
                    <CostumeAccordionItemButton
                        expanded={expandedItem === item.tag}>
                        <Text
                            tid={item.header}
                            className='text-blue-primary dark:text-blue-secondary'
                        />
                        <span className='text-blue-primary dark:text-blue-secondary'>
                            {expandedItem === item.tag ? (
                                <FaChevronUp />
                            ) : (
                                <FaChevronDown />
                            )}
                        </span>
                    </CostumeAccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                        <Text tid={item.body} />
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        )
    })

    return (
        <Wrapper $dark={theme === 'dark'}>
            <Accordion preExpanded={[expandedItem]}>{renderedItems}</Accordion>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    max-width: 840px;
    margin: 0 auto;

    .accordion {
        border: none;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .accordion__item {
        background-color: ${(props) => props.theme.accordionBg};
        border-radius: 15px;
        border: 1px solid
            ${(props) => (props.$dark ? '#00cccc' : 'transparent')};
        overflow: hidden;

        ${(props) =>
            !props.$dark &&
            css`
                box-shadow: 2px -2px 3px 2px #e2e2e2;
            `}
    }

    .accordion__heading {
        margin: 1.5rem;
    }

    .accordion__button {
        color: ${(props) => props.theme.heading};
    }

    .accordion__button:hover {
        background-color: #ddd;
    }

    .accordion__button:before {
        display: inline-block;
        content: '';
        height: 10px;
        width: 10px;
        margin-right: 12px;
        border-bottom: 2px solid currentColor;
        border-right: 2px solid currentColor;
        transform: rotate(-45deg);
    }

    .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(45deg);
    }

    [hidden] {
        display: none;
    }

    .accordion__panel {
        color: ${(props) => props.theme.pColor};
        padding: 0 1.5rem 1rem;
        animation: fadein 0.35s ease-in;
    }

    @keyframes fadein {
        0% {
            opacity: 0.5;
        }

        100% {
            opacity: 1;
        }
    }
`

const CostumeAccordionItemButton = styled(AccordionItemButton)`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: ${(props) =>
        props.expanded
            ? props.theme.activeAccordion
            : props.theme.accordionHeader};
    font-size: 1.1rem;
`
