import styled from 'styled-components'
import NewMarket from '../market-table/NewMarket'
import Image from 'next/image'
// import marketBg from ""

export default function MarketSection() {
    return (
        <div className='bg-gray-light dark:bg-dark-background'>
            <div className='relative z-10'>
                <NewMarket main={0} />
            </div>
        </div>
    )
}
