import styled from 'styled-components'

export const HeroSection = styled.div`
    /* background: linear-gradient(to bottom left, #1e429f 37.36%, #1c64f2 91.17%); */
    margin-top: 4rem;
    padding: 6rem 0;
    color: ${(props) => props.theme.color};

    @media screen and (max-width: 768px) {
        padding-top: 4rem;
    }
`

export const HeroWrapper = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    background-image: url('/assets/images/assets-on-lines.svg');
    background-repeat: no-repeat;
    /* background-size: 50%; */
    background-position-x: -150px;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`

export const DetailBox = styled.div`
    width: 50%;

    h1 {
        font-size: 3rem;
        /* font-weight: bold; */
        margin-bottom: 1.5rem;

        @media screen and (max-width: 1240px) {
            font-size: 2.4rem;
        }
        @media screen and (max-width: 768px) {
            font-size: 2.2rem;
        }
    }

    p {
        font-size: 1.1rem;
        margin-bottom: 2rem;
        /* color: #ebf5ff; */
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 3rem;
    }
`

export const CallToAction = styled.div`
    display: flex;
    gap: 1.2rem;

    @media screen and (max-width: 1240px) {
        flex-direction: column;
    }
    @media screen and (max-width: 768px) {
        flex-direction: row;
        gap: 0.8rem;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
    }

    button {
        @media screen and (max-width: 1240px) {
            width: 100%;
            padding: 1rem;
        }
        @media screen and (max-width: 768px) {
            width: 20%;
        }
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }

    input {
        color: #fff;
        outline: none;
        border: none;
        border-radius: 8px;
        padding: 0.9rem;
        font-size: 1.1rem;
        background: ${(props) => props.theme.heroInputBtnBg};
        width: 50%;

        &::placeholder {
            color: #a4cafe;
            font-size: 1rem;
        }

        @media screen and (max-width: 1240px) {
            width: 100%;
            font-size: 0.8rem;
            padding: 0.7rem;
        }
        @media screen and (max-width: 768px) {
            width: 60%;
        }
        @media screen and (max-width: 480px) {
            width: 100%;
        }
    }
`
export const QuickTradeSection = styled.div`
    width: 50%;
    /* background: url('/assets/images/header/quick-trade-bg.png') no-repeat center
        top/85%; */
    /* padding: 2.5rem; */

    @media screen and (max-width: 768px) {
        width: 100%;
        /* padding-inline: 0; */

        /* background-size: 70%; */
    }
    @media screen and (max-width: 480px) {
        /* background-size: 80%; */
    }
`

export const QuickTrade = styled.div`
    width: 70%;
    margin: 0 auto;
    background-color: #162e96;
    padding: 1.2rem;
    border-radius: 20px;

    @media screen and (max-width: 1240px) {
        width: 80%;
    }
    @media screen and (max-width: 1024px) {
        width: 90%;
    }
    @media screen and (max-width: 768px) {
        width: 60%;
    }
    @media screen and (max-width: 480px) {
        width: 100%;
    }

    h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 1.2rem;
    }

    .swap-btn {
        display: block;
        width: 30px;
        height: 30px;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        margin: 1.2rem auto;
    }

    .form {
        .form-control {
            display: flex;
            border: 1px solid ${(props) => props.theme.border};
            border-radius: 8px;
            overflow: hidden;

            & > input {
                width: 60%;
                padding: 0.5rem;
                font-size: 1rem;
                background-color: transparent;

                @media screen and (max-width: 1024px) {
                    font-size: 0.9rem;
                }
            }

            button {
                width: 90%;
                outline: none;
                border: 1px solid ${(props) => props.theme.border};
                border-radius: 0 5px 5px 0;
                background-color: #2f78f7;

                display: flex;
                justify-content: space-around;
                align-items: center;
            }
        }

        p {
            font-size: 0.75rem;
            margin: 2rem 0;
            text-align: left;
            direction: ltr;
        }
    }
`
export const QuickTradeButton = styled.button`
    text-align: center;
    width: 100%;
    border: 1px solid ${(props) => props.theme.border};
    border-radius: 8px;
    font-size: 1.2rem;
    background-color: ${(props) => props.theme.quickTradeBtn};
    padding: 0.5rem 0;

    :hover {
        background-color: ${(props) => props.theme.quickTradeBtn}50;
    }
`
