// import CoinSwiper from '@/components/common/ui/CoinSwiper'
// import NewMarket from '@/components/main/market-table/NewMarket'
// import { WhyUs } from '@/components/main/sections/WhyUs'
// import Section1 from '@/components/main/sections/Section1'
// import { MiniFaq } from '@/components/main/intro/MiniFaq'
import CoinAnimate from '@/components/common/ui/CoinAnimate'
import Header from '@/components/layout/header/Header'
import AboutUsSection from '@/components/main/sections/AboutUsSection'
import AppsSection from '@/components/main/sections/AppsSection'
import FAQ from '@/components/main/sections/FAQ'
import GetStartedSection from '@/components/main/sections/GetStartedSection'
import GuideSection from '@/components/main/sections/GuideSection'
import MarketSection from '@/components/main/sections/MarketSection'
import WhyUsSection from '@/components/main/sections/WhyUsSection'

export default function Home() {
    return (
        <>
            <Header />
            <div className={'w-[90%] xl:max-w-[1240px] mx-auto'}>
                {/* <Section1 /> */}

                <CoinAnimate />

                <AboutUsSection />
                <WhyUsSection />

                {/* <WhyUs /> */}
                {/* <MiniFaq /> */}
            </div>
            <MarketSection />
            <AppsSection />
            <FAQ />
            <GuideSection />
            <GetStartedSection />
        </>
    )
}
