import CustomUnderline from '@/components/common/ui/CustomUnderline'
import FaqAccordion from '@/components/common/ui/FaqAccordion'
import Text from '@/components/common/utils/Text'
import styled from 'styled-components'

export default function FAQ() {
    return (
        <SectionWrapper id='faq'>
            <Container>
                <h3>
                    <Text tid='faq__title--1' />
                </h3>

                <CustomUnderline width='20%' origin='center'>
                    <h4 className='fontBold'>
                        <Text tid='faq__title--2' />
                    </h4>
                </CustomUnderline>

                <FaqAccordion />
            </Container>
        </SectionWrapper>
    )
}

const SectionWrapper = styled.section`
    background: ${(props) => props.theme.gray50};
`

const Container = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    padding: 8rem 0;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }
    @media screen and (max-width: 1280px) {
        padding-block: 5rem;
    }

    h3 {
        text-align: center;
        color: ${(props) => props.theme.miniHeading};
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    h4 {
        text-align: center;
        color: ${(props) => props.theme.heading};
        font-size: 2.2rem;
        margin-bottom: 2rem;

        @media screen {
            font-size: 2rem;
        }
    }
`
