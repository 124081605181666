import styled, { css } from 'styled-components'

export const FeaturesSection = styled.div`
    color: ${(props) => props.theme.color};
`

export const FeaturesWrapper = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    position: relative;

    display: flex;
    ${(props) =>
        props.md
            ? css`
                  gap: 1rem;
                  padding: 2rem 0;
              `
            : css`
                  justify-content: space-between;
                  padding: 1.2rem 0;
              `}
    align-items: center;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }

    .swiper-pagination {
        position: static;
        display: block;
        margin-top: 1rem;

        ${({ $light }) =>
            $light &&
            css`
                .swiper-pagination-bullet {
                    background-color: #024796 !important;
                }
            `}
    }
`

export const FeatureBox = styled.div`
    padding: 1.2rem 4rem;
    border-radius: 8px;

    ${(props) =>
        props.md &&
        css`
            /* width: 70%;
            margin-inline: auto; */
        `}

    @media screen and (max-width: 1240px) {
        padding: 1rem 2rem;
    }

    div {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        h3 {
            font-size: 1rem;

            .feature__number {
                margin: 0.7rem;
                font-size: 2rem;

                @media screen and (max-width: 1240px) {
                    font-size: 1.5rem;
                }
            }
        }
    }

    p {
        margin-top: 0.5rem;
        font-size: 1rem;
        opacity: 40%;
        text-align: center;

        @media screen and (max-width: 1240px) {
            font-size: 0.8rem;
        }
    }
`

export const FeatureDivider = styled.div`
    width: 1.5px;
    height: 5rem;
    border-radius: 10px;
`
