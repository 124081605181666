import Text from '@/components/common/utils/Text'
import Image from 'next/image'
import styled, { css } from 'styled-components'

import GuidePic from 'public/assets/images/guide/guide.svg'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import { useMainContext } from '@/core/contexts/main'

const BOXES = [
    {
        id: 1,
        heading: 'guide__box--1__header',
        body: 'guide__box--1__body',
    },
    {
        id: 2,
        heading: 'guide__box--2__header',
        body: 'guide__box--2__body',
    },
    {
        id: 3,
        heading: 'guide__box--3__header',
        body: 'guide__box--3__body',
    },
    {
        id: 4,
        heading: 'guide__box--4__header',
        body: 'guide__box--4__body',
    },
]

export default function GuideSection() {
    const { theme } = useMainContext()

    const renderedBoxes = BOXES.map((box) => {
        const { id, heading, body } = box

        return (
            <div
                className='guide__box dark:border dark:border-blue-secondary '
                key={id}>
                <div className='hidden md:flex items-center justify-center md:w-[40px] md:h-[40px] bg-blue-primary dark:bg-blue-secondary dark:text-black rounded-full text-white fontBold'>
                    {id}
                </div>
                <div>
                    <h3 className='guide__box__heading text-blue-primary dark:text-blue-secondary'>
                        <Text tid={heading} />
                    </h3>
                    <p className='guide__box__body'>
                        <Text tid={body} />
                    </p>
                </div>
            </div>
        )
    })

    return (
        <SectionWrapper>
            <GuideDetails $dark={theme === 'dark'}>
                <h3 className='fontBold'>
                    <CustomUnderline width='30%'>
                        <Text tid='guide__title' />
                    </CustomUnderline>
                </h3>
                {renderedBoxes}
            </GuideDetails>
            <div>
                <Image
                    src={GuidePic}
                    width={500}
                    alt='simple, fast, and practical!'
                    className='w-full min-[480px]:w-2/3 mx-auto md:w-full'
                />
            </div>
        </SectionWrapper>
    )
}

const SectionWrapper = styled.section`
    max-width: 1240px;
    margin: 0 auto;
    padding: 8rem 0;

    display: flex;
    justify-content: space-between;
    gap: 4rem;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }
    @media screen and (max-width: 1024px) {
        padding-block: 5rem;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
`
const GuideDetails = styled.div`
    max-width: 660px;

    & > h3 {
        color: ${(props) => props.theme.heading};
        font-size: 2.1rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 1024px) {
            font-size: 1.9rem;
        }
    }

    .guide__box {
        background: ${(props) => props.theme.gray50};
        padding: 1.5rem;
        border-radius: 25px;

        display: flex;
        align-items: center;
        gap: 1rem;

        ${(props) =>
            !props.$dark &&
            css`
                box-shadow: 2px -2px 3px 2px #e2e2e2;
            `}

        .guide__box__heading {
            font-size: 1.25rem;
            margin-bottom: 0.6rem;
        }

        .guide__box__body {
            color: ${(props) => props.theme.pColor};
            font-size: 0.9rem;
        }
    }

    .guide__box + .guide__box {
        margin-top: 1.1rem;
    }
`
