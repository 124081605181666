import { HOME, ICON_URL, SOCKET_URL } from '@/core/constants/urls'
import { useMarketsTable } from '@/core/hooks/markets/useMarketsTable'
import Image from 'next/image'
import styled from 'styled-components'
import { coinsInfo } from '@/core/constants/coins'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import { Autoplay, Navigation, Pagination } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { formatNumber } from '@/core/utils/common'
import { PropagateLoader } from 'react-spinners'
import { useMainContext } from '@/core/contexts/main'

export default function CoinAnimate() {
    const { theme } = useMainContext()
    const { coins } = useMarketsTable()
    const { width } = useWindowSize()

    const responsiveSlideCounts = width > 768 ? 6 : width > 480 ? 4 : 1

    const renderedItems = coins.map((coin) => {
        const coinInfo = coinsInfo.find(
            (coinInfo) => coinInfo.name === coin.coin
        )
        const color = coinInfo?.color || (theme === 'dark' ? '#fff' : '#000')
        const changes = coin.changes['24h'].changePresent

        return (
            <SwiperSlide key={coin.id}>
                <ComponentWrapper
                    href={HOME + '/trade'}
                    className='inline-flex flex-col items-center justify-center'>
                    <ShapeContainer className='relative shape-container mb-4'>
                        <Image
                            src={ICON_URL + `assets/icon/${coin.id}.png`}
                            width={60}
                            height={60}
                            className='absolute top-[30%] left-[30%] z-[100]'
                            alt={coin.id}
                        />
                        <svg
                            className='shape original'
                            viewBox='0 0 60 60'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M25.8855 1.98935C28.3389 0.206845 31.6611 0.206849 34.1145 1.98936L56.3193 18.1221C58.7727 19.9046 59.7993 23.0642 58.8622 25.9483L50.3807 52.0517C49.4436 54.9358 46.7559 56.8885 43.7233 56.8885H16.2767C13.2441 56.8885 10.5564 54.9358 9.61927 52.0517L1.13779 25.9483C0.200668 23.0642 1.22728 19.9046 3.68069 18.1221L25.8855 1.98935Z'
                                fill='#24222F'
                            />
                        </svg>
                        <svg
                            className='shape outline'
                            viewBox='0 0 60 60'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                                d='M33.8206 2.39387L56.0254 18.5266C58.3036 20.1818 59.2569 23.1157 58.3867 25.7938L49.9052 51.8972C49.035 54.5753 46.5393 56.3885 43.7233 56.3885H16.2767C13.4607 56.3885 10.965 54.5753 10.0948 51.8972L1.61332 25.7938C0.743134 23.1157 1.69641 20.1818 3.97458 18.5266L26.1794 2.39386C28.4576 0.738676 31.5424 0.738679 33.8206 2.39387Z'
                                stroke={color || '#fff'}
                            />
                        </svg>
                    </ShapeContainer>
                    <TextContainer className='relative flex items-center text-sm mt-0 w-full h-20'>
                        <div className='vertical-line'></div>
                        <div className='text original-text left fontBold'>
                            {coin.fa}
                        </div>
                        <div className='text original-text right flex flex-col items-end'>
                            <span className='fontBold'>
                                {coin.coin.toUpperCase()}
                            </span>
                            <span className='dark:text-gray-50 dark:text-opacity-50'>
                                {coin.name}
                            </span>
                        </div>
                        <div
                            className={`text new-text left fontBold ${
                                changes?.startsWith('-')
                                    ? 'text-red-500'
                                    : 'text-green-500'
                            }`}
                            dir='ltr'>
                            {changes || 0}%
                        </div>
                        <div className='text new-text right flex flex-col items-end'>
                            <span className='fontBold'>
                                {formatNumber(coin.value)}
                            </span>
                            <span className='dark:text-gray-50 dark:text-opacity-50'>
                                {formatNumber(coin.buy)}
                            </span>
                        </div>
                    </TextContainer>
                </ComponentWrapper>
            </SwiperSlide>
        )
    })

    return (
        <CoinAnimateSection>
            {coins.length > 0 ? (
                <Swiper
                    // pagination={{ clickable: true }}
                    spaceBetween={10}
                    slidesPerView={responsiveSlideCounts}
                    // navigation
                    modules={[Autoplay]}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                >
                    {renderedItems}
                </Swiper>
            ) : (
                <div className='flex justify-center items-center'>
                    <PropagateLoader
                        color={theme === 'dark' ? 'yellow' : 'blue'}
                    />
                </div>
            )}
        </CoinAnimateSection>
    )
}

const CoinAnimateSection = styled.div`
    margin-top: 5rem;

    .swiper-pagination {
        position: static;
        display: block;
        margin-top: 1rem;
    }
`

const ShapeContainer = styled.div`
    width: 150px;
    height: 150px;
    position: relative;

    .shape {
        width: 100%;
        height: 100%;
        position: absolute;
        transition: transform 0.3s ease;
    }

    .shape.original {
        z-index: 1;
    }

    .shape.outline {
        transform: rotate(-180deg) translateY(-10px);
        z-index: -1;
    }

    &:hover .outline {
        transform: rotate(0deg);
        z-index: 1;
    }
`

const TextContainer = styled.div`
    .vertical-line {
        width: 5px;
        height: 50px;
        background-color: #333;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .text {
        position: absolute;
        transition: transform 0.3s ease, opacity 0.3s ease;
        width: 45%;
    }

    .original-text.left {
        left: 0;
        /* left: -20px; */
    }

    .original-text.right {
        right: 0;
    }

    .new-text {
        opacity: 0;
    }

    .new-text.left {
        left: 0;
        transform: translateX(-100%);
    }

    .new-text.right {
        right: 0;
        transform: translateX(100%);
    }
`

const ComponentWrapper = styled.a`
    width: 100%;

    &:hover ${ShapeContainer} .outline {
        transform: rotate(0deg);
        z-index: 1;
    }

    &:hover ${TextContainer} .original-text.left {
        transform: translateX(50%);
        opacity: 0;
    }

    &:hover ${TextContainer} .original-text.right {
        transform: translateX(-50%);
        opacity: 0;
    }

    &:hover ${TextContainer} .new-text.left {
        transform: translateX(0);
        opacity: 1;
    }

    &:hover ${TextContainer} .new-text.right {
        transform: translateX(0);
        opacity: 1;
    }
`
