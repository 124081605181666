import Hero from './Hero'
import Features from './Features'

export default function Header() {

    return (
        <header>
            <Hero />
            <Features />
        </header>
    )
}
