import Text from '@/components/common/utils/Text'
import Image from 'next/image'
import styled from 'styled-components'

import PhonePic from 'public/assets/images/apps/mockup.png'
import { useMainContext } from '@/core/contexts/main'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'

const APP_LINKS = [
    {
        icon: 'google-play.svg',
        name: 'google-play',
        link:
            'https://play.google.com/store/apps/details?id=com.topkenz.android',
    },
    {
        icon: 'bazar.svg',
        name: 'bazar',
        link: 'https://cafebazaar.ir/app/com.topkenz.android',
    },
    {
        icon: 'myket.svg',
        name: 'myket',
        link:
            'https://myket.ir/app/com.topkenz.android?utm_source=search-ads-gift&utm_medium=cpc',
    },
]

export default function AppsSection() {
    const { theme } = useMainContext()
    const { width } = useWindowSize()

    return (
        <div className='relative mt-10'>
            <Image
                src={'/assets/images/apps/bg.svg'}
                width={400}
                height={200}
                className={`hidden lg:block lg:w-1/3 absolute top-1/2 -translate-y-1/2 right-0 -z-10`}
            />
            <Container>
                <div className='w-full md:w-1/3'>
                    <Image
                        src={PhonePic}
                        alt='Download Limani EX on your mobile phone!'
                        className='w-1/3 mx-auto md:w-[60%] md:mx-0 mb-6 md:mb-0 mt-4 md:mt-0 md:me-auto'
                    />
                </div>
                <SectionDetails $theme={theme}>
                    <h3 className='fontBold'>
                        <Text tid='apps__title--1' />
                    </h3>
                    <h4 className='fontBold'>
                        <Text tid='apps__title--2' />
                    </h4>
                    <p>
                        <Text tid='apps__body' />
                    </p>
                    <div className='grid grid-cols-3 md:grid-cols-5 gap-x-12 gap-y-4'>
                        <div className='flex items-center justify-center md:justify-normal col-span-full md:col-span-2 gap-x-2 bg-blue-primary-dark text-white px-2 py-2 md:py-0 rounded-lg group cursor-pointer'>
                            <Image
                                src={'/assets/images/apps/QR.svg'}
                                width={60}
                                height={60}
                                className='transition-transform group-hover:scale-[4] p-1 bg-blue-primary-dark rounded-md'
                            />
                            <h5 className='text-xs'>
                                <Text tid='apps__download__title' />
                            </h5>
                        </div>
                        {APP_LINKS.map((item) => {
                            return (
                                <a
                                    href={item.link}
                                    key={item.name}
                                    className='flex flex-col gap-y-2 items-center p-4 bg-blue-primary-dark rounded-lg transition-colors hover:border-cyan-300 border border-transparent hover:bg-cyan-700 group'>
                                    <Image
                                        src={`/assets/images/apps/${item.icon}`}
                                        width={25}
                                        height={25}
                                        // style={{ objectFit: 'cover' }}
                                        className='object-cover group-hover:-translate-y-1 group-hover:scale-95 transition-transform'
                                    />
                                    <h5>
                                        <Text
                                            tid={item.name}
                                            className='text-white text-xs'
                                        />
                                    </h5>
                                </a>
                            )
                        })}
                    </div>
                </SectionDetails>
            </Container>
        </div>
    )
}

const Container = styled.div`
    max-width: 1240px;
    margin: 0 auto;
    padding: 10rem 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1280px) {
        width: 90%;
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-block: 5rem;
    }
`

const SectionDetails = styled.div`
    width: 66.66%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    h3 {
        color: ${(props) => props.theme.miniHeading};
        font-size: 1rem;
        margin-bottom: 0.5rem;
    }

    h4 {
        color: ${(props) => props.theme.heading};
        font-size: 2.2rem;
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
            font-size: 1.7rem;
        }
    }

    & > p {
        color: ${(props) => props.theme.pColor2};
        font-size: 1rem;
        margin-bottom: 2rem;
    }
`
