import { MainButton } from '@/components/common/buttons/MainButton'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import Text from '@/components/common/utils/Text'
import { routes } from '@/core/constants/routes'
import { HOME } from '@/core/constants/urls'
import Link from 'next/link'
import styled from 'styled-components'

export default function GetStartedSection() {
    return (
        <SectionWrapper className='fontBold'>
            <h2>
                <Text tid='get-started__title--1' />
            </h2>
            <h2>
                <CustomUnderline
                    width='40%'
                    origin='center'
                    className='text-center'>
                    <Text tid='get-started__title--2__part1' />
                    <Text
                        tid='get-started__title--2__part2'
                        className='text-blue-secondary'
                    />
                    <Text tid='get-started__title--2__part3' />
                </CustomUnderline>
            </h2>
            <MainButton
                link
                className='!bg-blue-secondary hover:!bg-blue-primary transition-colors text-blue-primary-dark hover:text-white text-xs md:text-base'>
                <Link href={HOME + routes.register}>
                    <Text tid='get-started__btn' />
                </Link>
            </MainButton>
        </SectionWrapper>
    )
}

const SectionWrapper = styled.section`
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    color: ${(props) => props.theme.color};
    background: url('/assets/images/getStarted/bg.png') no-repeat center
        bottom/contain;

    @media screen and (max-width: 1024px) {
        padding: 2rem 0;
        background-size: cover;
        background-position: center left;
    }

    h2 {
        font-size: 1.8rem;

        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 1.2rem;
            margin-inline: 2rem;
        }
        @media screen and (max-width: 480px) {
            font-size: 0.9rem;
        }
    }

    h2 + h2 {
        margin-bottom: 2rem;
    }
`
