import { useRouter } from 'next/router'
import Image from 'next/image'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import styled from 'styled-components'

import { Button } from '@/components/common/buttons/Button'
import Text from '@/components/common/utils/Text'
import GlobeImage from '../../../../public/assets/images/about/globe.png'
import Link from 'next/link'
import { routes } from '@/core/constants/routes'
import CustomUnderline from '@/components/common/ui/CustomUnderline'

export default function AboutUsSection() {
    const { locale } = useRouter()

    const buttonIcon = locale === 'fa' ? <FaArrowLeft /> : <FaArrowRight />

    return (
        <div className='flex flex-col md:flex-row w-full gap-10 py-14 md:py-20 lg:py-40 '>
            <AboutDetails>
                <h3>
                    <Text tid={'about-us__title--1'} />
                </h3>
                <CustomUnderline width='30%'>
                    <h4 className='fontBold'>
                        <Text tid={'about-us__title--2'} />
                    </h4>
                </CustomUnderline>
                <p>
                    <Text tid={'about-us__body'} />
                </p>
                <button className='border border-green-primary hover:bg-green-primary hover:text-white transition-colors rounded-lg'>
                    <Link href={routes.about}>
                        <Text tid={'about-us__btn'} />
                        {buttonIcon}
                    </Link>
                </button>
            </AboutDetails>
            <div className='w-4/5 mx-auto md:w-1/2 md:mx-0 md:-mt-14'>
                <Image src={GlobeImage} width={600} height={400} />
            </div>
        </div>
    )
}

const AboutDetails = styled.div`
    width: 50%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    h3 {
        font-size: 1rem;
        color: ${(props) => props.theme.miniHeading};
        margin-bottom: 0.5rem;
    }

    h4 {
        font-size: 2.3rem;
        color: ${(props) => props.theme.heading};
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }
    }

    p {
        color: ${(props) => props.theme.pColor};
        line-height: 1.6;
        margin-bottom: 2rem;
    }

    button > a {
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 0.5rem 1rem;
    }
`
