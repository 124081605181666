import Text from '@/components/common/utils/Text'
import {
    CallToAction,
    DetailBox,
    HeroSection,
    HeroWrapper,
    QuickTrade,
    QuickTradeButton,
    QuickTradeSection,
} from './styles/Hero.styled'
import { Button } from './styles/MainNav.styled'
import ToomanRow from '@/components/main/otc/otc2/ToomanRow'
import CryptoCoinSelect from '@/components/main/otc/otc2/CryptoCoinSelect'
import { SwapIcon } from '@/components/common/icons'
import { useRouter } from 'next/router'
import { useOtc } from '@/components/main/otc/useOtc'
import { useRunAfterUpdate } from '@/core/hooks/common/setting'
import {
    deformatNumber,
    formatNumber,
    onInputValueChangeUtil,
} from '@/core/utils/common'
import Link from 'next/link'
import { routes } from '@/core/constants/routes'
import { HOME } from '@/core/constants/urls'
import OtcInvoiceModal from '@/components/modal/OtcInvoiceModal'
import ModalLayout from '../ModalLayout'
import OtcErrorModal from '@/components/modal/OtcErrorModal'
import DigiOtc from '@/components/main/otc/otc2/DigiOtc'

export default function Hero() {
    const { locale: lang } = useRouter()

    const {
        profile,
        balance,
        onBalanceClick,
        coin,
        price,
        onPriceChange,
        clientServed,
        volume,
        onVolumeChange,
        setCoin,
        onAction,
        modal,
        toomanCoin,
        onClose,
        limits,
        type,
        setType,
    } = useOtc()

    const afterUpdate = useRunAfterUpdate()
    const onChange = (e, inputType) => {
        const value = onInputValueChangeUtil(e, afterUpdate)
        if (inputType === 'first') {
            type === 'buy' ? onPriceChange(value) : onVolumeChange(value)
        } else {
            type === 'sell' ? onPriceChange(value) : onVolumeChange(value)
        }
    }

    const baseChangeFormula = coin
        ? `1 ${coin.coin.toUpperCase()} = ${formatNumber(coin?.sell) || ''} IRT`
        : ''

    return (
        <HeroSection className='bg-gray-light dark:bg-gradient-to-bl from-blue-primary to-blue-primary-dark'>
            <HeroWrapper>
                <DetailBox className='text-blue-primary dark:text-white'>
                    <h1 className='fontBold'>
                        <Text tid='hero-title' />
                    </h1>
                    <p>
                        <Text tid='hero-paragraph' />
                    </p>
                    <CallToAction>
                        <input
                            type='text'
                            placeholder={
                                lang === 'fa'
                                    ? 'آدرس ایمیل خود را وارد کنید'
                                    : 'Enter your email address'
                            }
                        />
                        <Button bg='primary' paddingX='2rem' link>
                            <a href={HOME + routes.register}>
                                <Text tid='register-now' />
                            </a>
                        </Button>
                    </CallToAction>
                </DetailBox>

                <QuickTradeSection>
                    <div className='trade-section-container'>
                        <div className={'w-2/3 '}>
                            <div
                                className={
                                    'card block text-black dark:text-white'
                                }>
                                <DigiOtc />
                            </div>
                        </div>
                    </div>
                </QuickTradeSection>
            </HeroWrapper>
        </HeroSection>
    )
}
