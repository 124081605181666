import {
    DigitalCurrencyIcon,
    TradeIcon,
    UserGroupIcon,
} from '@/components/common/icons'
import {
    FeatureBox,
    FeatureDivider,
    FeaturesSection,
    FeaturesWrapper,
} from './styles/Features.styled'
import Text from '@/components/common/utils/Text'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { useWindowSize } from '@/core/hooks/common/useWindowSize'
import React, { useEffect } from 'react'
import { Autoplay, Pagination } from 'swiper'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { useMainContext } from '@/core/contexts/main'

const FEATURE_BOXES = [
    {
        icon: <UserGroupIcon />,
        quantityLabel: 'feature__quantity--1',
        titleLabel: 'feature__title--1',
        bodyLabel: 'feature__body--1',
    },
    {
        icon: <TradeIcon />,
        quantityLabel: 'feature__quantity--2',
        titleLabel: 'feature__title--2',
        bodyLabel: 'feature__body--2',
    },
    {
        icon: <DigitalCurrencyIcon />,
        quantityLabel: 'feature__quantity--3',
        titleLabel: 'feature__title--3',
        bodyLabel: 'feature__body--3',
    },
]

export default function Features() {
    const { theme } = useMainContext()
    const { width } = useWindowSize()

    useEffect(() => {
        console.log(width)
    }, [width])

    const renderedBoxes = FEATURE_BOXES.map((box, idx, array) => {
        const { icon, bodyLabel, quantityLabel, titleLabel } = box

        return (
            <React.Fragment key={titleLabel}>
                {idx !== 0 && idx < array.length && (
                    <FeatureDivider className='bg-blue-primary dark:bg-blue-primary-dark' />
                )}
                <FeatureBox className='bg-blue-primary dark:bg-blue-primary-dark'>
                    <div>
                        {icon}
                        <h3>
                            <span className='feature__number'>
                                <Text tid={quantityLabel} />
                            </span>
                            <Text tid={titleLabel} />
                        </h3>
                    </div>
                    <p>
                        <Text tid={bodyLabel} />
                    </p>
                </FeatureBox>
            </React.Fragment>
        )
    })

    const respBoxes = (
        <Swiper
            pagination={{ clickable: true }}
            spaceBetween={10}
            slidesPerView={1}
            modules={[Pagination, Autoplay]}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}>
            {FEATURE_BOXES.map((box, idx, array) => {
                const { icon, bodyLabel, quantityLabel, titleLabel } = box

                return (
                    <SwiperSlide key={titleLabel}>
                        <FeatureBox
                            md={width < 768}
                            className='bg-blue-primary dark:bg-blue-primary-dark'>
                            <div>
                                {icon}
                                <h3>
                                    <span className='feature__number'>
                                        <Text tid={quantityLabel} />
                                    </span>
                                    <Text tid={titleLabel} />
                                </h3>
                            </div>
                            <p>
                                <Text tid={bodyLabel} />
                            </p>
                        </FeatureBox>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )

    return (
        <FeaturesSection className='bg-gray-dark dark:bg-blue-primary'>
            <FeaturesWrapper md={width < 768} $light={theme === 'light'}>
                {width >= 768 ? renderedBoxes : respBoxes}
            </FeaturesWrapper>
        </FeaturesSection>
    )
}
