import {
    DocumentIcon,
    SecurityIcon,
    SupportIcon,
    WageIcon,
} from '@/components/common/icons'
import CustomUnderline from '@/components/common/ui/CustomUnderline'
import Text from '@/components/common/utils/Text'
import { useMainContext } from '@/core/contexts/main'
import styled, { css } from 'styled-components'

const WHY_US_BOXES = [
    {
        icon: <DocumentIcon />,
        header: 'why-us__box--1__header',
        body: 'why-us__box--1__body',
    },
    {
        icon: <SecurityIcon />,
        header: 'why-us__box--2__header',
        body: 'why-us__box--2__body',
    },
    {
        icon: <WageIcon />,
        header: 'why-us__box--3__header',
        body: 'why-us__box--3__body',
    },
    {
        icon: <SupportIcon />,
        header: 'why-us__box--4__header',
        body: 'why-us__box--4__body',
    },
]

export default function WhyUsSection() {
    const { theme } = useMainContext()

    const renderedBoxes = WHY_US_BOXES.map((box) => {
        return (
            <div
                className='dark:border border-green-secondary'
                key={box.header}>
                <h4>
                    {box.icon}
                    <Text tid={box.header} />
                </h4>
                <p>
                    <Text tid={box.body} />
                </p>
            </div>
        )
    })

    return (
        <div className='flex flex-col md:flex-row w-full gap-10 py-14 md:py-20 lg:py-40 '>
            <WhyDetails>
                <CustomUnderline width='30%'>
                    <h3 className='fontBold'>
                        <Text tid={'why-us__title'} />
                    </h3>
                </CustomUnderline>
                <p>
                    <Text tid={'why-us__body'} />
                </p>
            </WhyDetails>
            <WhyBoxes $theme={theme}>{renderedBoxes}</WhyBoxes>
        </div>
    )
}

const WhyDetails = styled.div`
    width: 33%;

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    h3 {
        font-size: 2.3rem;
        color: ${(props) => props.theme.heading};
        margin-bottom: 1rem;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }
    }

    p {
        color: ${(props) => props.theme.pColor2};
        line-height: 1.6;
        margin-bottom: 1rem;
    }
`
const WhyBoxes = styled.div`
    width: 66%;
    display: flex;
    flex-wrap: wrap;
    gap: 2.5rem;

    @media screen and (max-width: 1240px) {
        gap: 1rem;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
    @media screen and (max-width: 480px) {
        flex-direction: column;
    }

    div {
        width: 47%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background: ${(props) => props.theme.gray50};
        padding: 2rem 1.5rem;
        border-radius: 20px;

        ${(props) =>
            props.$theme === 'light' &&
            css`
                box-shadow: 3px -3px 3px 2px #e2e2e2;
            `}

        @media screen and (max-width: 480px) {
            width: 100%;
        }

        h4 {
            display: flex;
            align-items: center;
            gap: 1rem;
            color: ${(props) => props.theme.heading};

            span {
                font-size: 1.5rem;

                @media screen and (max-width: 1024px) {
                    font-size: 1.3rem;
                }
                @media screen and (max-width: 768px) {
                    font-size: 1.2rem;
                }
            }
        }

        p {
            color: ${(props) => props.theme.pColor};
            font-size: 0.9rem;
        }
    }
`
